export default [
  {
    id: 0,
    companyName: 'Google',
    protestColor: '#EE343D',
    event:
      'An online petition is currently circulating which is calling on Google to pay its fair share of taxes, following news that the company has utilized foreign shell companies to shelter more than $33 Billion USD of revenue. Of which, $2 Billion USD worth of taxes is owed around the world.',
    date: 'Ongoing',
    year: 'Ongoing',
    theme: 'Tax Evasion',
    protest: 'Tax Evasion',
    how: 'Petition (Online)',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '136,500',
    location: 'Internet',
    country: 'Internet',
    source: ['https://actions.sumofus.org/a/google-taxes'],
  },
  {
    id: 1,
    companyName: 'Uber',
    protestColor: '#EE343D',
    event:
      'Coordinated efforts by Uber and Lyft drivers to switch off app and create an artificial surge in demand for drivers, subsequently increasing the fare. This tactic has been used in response to drivers struggling to make a living-wage due to the companies inherently flawed business model.',
    date: 'Ongoing',
    year: 'Ongoing',
    theme: 'Labor / Wage',
    protest: 'Labor/Wage',
    how: 'Protest',
    actionDoneBy: 'Drivers',
    numberOfPeople: '',
    location: 'Washington D.C.',
    country: 'USA',
    source: ['https://www.theregister.com/2019/05/20/lyft_uber_surge_pricing/'],
  },
  {
    id: 2,
    companyName: 'HP',
    protestColor: '#263692',
    event:
      'Over 1.7 million people sign an online petition demanding HP, ABP, Veolia, Barclays, Caterpillar and G4S withdraw investments and respect international law.',
    date: 'Ongoing',
    year: 'Ongoing',
    theme: 'Ethics (Palestine)',
    protest: 'Ethics',
    how: 'Petition (Online)',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '1,725,922+',
    location: 'Internet',
    country: 'Internet',
    source: [
      'https://secure.avaaz.org/campaign/en/israel_palestine_this_is_how_it_ends_loc/',
    ],
  },
  {
    id: 3,
    companyName: 'Google',
    protestColor: '#263692',
    event:
      'Azzad Asset Management, filed a shareholder petition against Alphabet Inc. in order to evaluate the risk and potential impact on investors carrying out project Dragonfly may have caused.',
    date: '12/17/2019',
    year: '2019',
    theme: 'Ethics (Dragonfly)',
    protest: 'Ethics',
    how: 'Shareholder Petition',
    actionDoneBy: 'Shareholders',
    numberOfPeople: '',
    location: 'USA',
    country: 'USA',
    source: [
      'https://www.politico.com/story/2018/12/19/google-shareholder-revolts-project-dragonfly-1037966',
    ],
  },
  {
    id: 4,
    companyName: 'Uber',
    protestColor: '#EE343D',
    event:
      "A caravan of dozens of drivers drove a 500-mile route from Los Angeles to Sacramento, stopping in San Francisco to protest at Uber's HQ. Like other Uber and Lyft protests, this demonstration was centered around drivers' dissatisfaction with being considered independent contractors.",
    date: '08/28/2019',
    year: '2019',
    theme: 'Labor / Wage',
    protest: 'Labor/Wage',
    how: 'Protest',
    actionDoneBy: 'Drivers',
    numberOfPeople: '24+',
    location: 'Los Angeles, San Francisco, Sacramento',
    country: 'USA',
    source: [
      'https://www.cnet.com/news/uber-and-lyft-driver-caravan-lands-in-californias-capital-demanding-a-living-wage/',
    ],
  },
  {
    id: 5,
    companyName: 'Palantir',
    protestColor: '#263692',
    event:
      "Approximately 70 people protested near Palantir Technologies' cafeteria to garner the attention of Palantir employees, so that they might also take a stand against the company's sale of software to ICE.",
    date: '08/17/2019',
    year: '2019',
    theme: 'Ethics (ICE)',
    protest: 'Ethics',
    how: 'Protest',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '70',
    location: 'Palo Alto',
    country: 'USA',
    source: [
      'https://www.businessinsider.de/palantir-protest-palo-alto-activists-ice-contracts-2019-8?r=US&IR=T',
    ],
  },
  {
    id: 6,
    companyName: 'Google',
    protestColor: '#263692',
    event:
      'A letter signed by over a thousand Google employees, demanded that the company "publicly commit not to support CBP, ICE, or ORR with any infrastructure, funding, or engineering resources, directly or indirectly, until they stop engaging in human rights abuses."',
    date: '08/14/2019',
    year: '2019',
    theme: 'Ethics (CBP, ICE, ORR)',
    protest: 'Ethics',
    how: 'Letter',
    actionDoneBy: 'Tech Employees',
    numberOfPeople: '1495',
    location: 'Internet',
    country: 'Internet',
    source: [
      'https://medium.com/@no.gcp.for.cbp/google-must-stand-against-human-rights-abuses-nogcpforcbp-88c60e1fc35e',
    ],
  },
  {
    id: 7,
    companyName: 'Amazon',
    protestColor: '#263692',
    event:
      "Hundreds of demonstrators gathered in New York inside and outside an Amazon Books store, protesting the company's involvement with ICE. 44 people were reported to have been arrested.",
    date: '08/11/2019',
    year: '2019',
    theme: 'Ethics (ICE)',
    protest: 'Ethics',
    how: 'Protest',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '200+',
    location: 'Manhattan, New York',
    country: 'USA',
    source: [
      'https://www.cbsnews.com/news/protesters-gather-at-amazon-in-manhattan-to-protest-companys-ice-involvement/',
    ],
  },
  {
    id: 8,
    companyName: 'Google',
    protestColor: '#27AB4F',
    event:
      'Google settles an age-discrimination class action lawsuit after paying out $11 million USD, without admitting liability. The case was brought by hundreds of people who believed to be discriminated against because of their age.',
    date: '07/29/2019',
    year: '2019',
    theme: 'Age Discrimination',
    protest: 'Discrimination',
    how: 'Class-Action Lawsuit',
    actionDoneBy: 'Tech Employees',
    numberOfPeople: '227',
    location: 'USA',
    country: 'USA',
    source: [
      'https://www.business-humanrights.org/en/usa-google-settles-age-discrimination-class-action-lawsuit-for-11-million',
    ],
  },
  {
    id: 9,
    companyName: 'Uber',
    protestColor: '#EE343D',
    event:
      "Protestors gathered outside of Uber's headquarters in San Francisco and demanded higher pay and the ability to unionize.",
    date: '07/19/2019',
    year: '2019',
    theme: 'Labor / Wage',
    protest: 'Labor/Wage',
    how: 'Protest',
    actionDoneBy: 'Drivers',
    numberOfPeople: '40',
    location: 'San Francisco',
    country: 'USA',
    source: [
      'https://www.cnet.com/news/uber-lyft-drivers-stage-protest-for-better-working-conditions/',
    ],
  },
  {
    id: 10,
    companyName: 'Facebook',
    protestColor: '#EE343D',
    event:
      "Several dozen workers and tech employees protested against unfair living wages at Facebook's headquarters.",
    date: '07/16/2019',
    year: '2019',
    theme: 'Labor / Wage',
    protest: 'Labor/Wage',
    how: 'Protest',
    actionDoneBy: 'Workers',
    numberOfPeople: '36+',
    location: 'Menlo Park, California',
    country: 'USA',
    source: [
      'https://www.sfchronicle.com/business/article/Facebook-cafeteria-workers-protest-pay-hours-in-14100891.php',
    ],
  },
  {
    id: 11,
    companyName: 'Twitter',
    protestColor: '#263692',
    event:
      "In response to Twitter blocking the accounts of Julian Assange and Chelsea Manning, credited whistle-blowers, a small group of protestors organized outside of Twitter's headquarters in San Francisco",
    date: '07/08/2019',
    year: '2019',
    theme: 'Ethics',
    protest: 'Ethics',
    how: 'Protest',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '24+',
    location: 'San Francisco',
    country: 'USA',
    source: [
      'https://twitter.com/fordfischer/status/1116497011208007680?lang=en',
      'https://www.indybay.org/newsitems/2019/07/07/18824518.php',
    ],
  },
  {
    id: 12,
    companyName: 'Twitter',
    protestColor: '#263692',
    event:
      '"Decentralize and Boycott Social Media" campaign gets over 6,000 signatures while others participate in a 24 hour boycott of the social media service between July 4-5th.',
    date: '07/04/2019',
    year: '2019',
    theme: 'Ethics',
    protest: 'Ethics',
    how: 'Petition (Online)',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '6,390+',
    location: 'Internet',
    country: 'Internet',
    source: [
      'https://www.change.org/p/social-media-executives-decentralize-social-media-a-declaration-of-digital-independence?recruiter=978620935&utm_source=share_petition&utm_medium=copylink',
    ],
  },
  {
    id: 13,
    companyName: 'Facebook',
    protestColor: '#27AB4F',
    event:
      "Following Facebook's announcement of a massive data breach which led 30 million users to be left vulnerable, a class action lawsuit has been allowed to move forward by a judge.",
    date: '06/24/2019',
    year: '2019',
    theme: 'Class Action Lawsuit',
    protest: 'Class Action Lawsuit',
    how: 'Petition (Online)',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '',
    location: 'Internet',
    country: 'Internet',
    source: [
      'https://www.cyberscoop.com/facebook-class-action-lawsuit-moves-forward/',
    ],
  },
  {
    id: 14,
    companyName: 'Palantir',
    protestColor: '#263692',
    event:
      "Hundreds of protesters rallied outside Palantir technologies' headquarters in Palo Alto, concerned with the company's production of software which facilitates ICE raids.",
    date: '06/12/2019',
    year: '2019',
    theme: 'Ethics(ICE)',
    protest: 'Ethics',
    how: 'Protest',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '200+',
    location: 'Palo Alto',
    country: 'USA',
    source: [
      'https://www.liberationnews.org/hundreds-join-united-action-in-palo-alto-calif-to-shut-down-palantir-technologies-for-complicity-with-ice/',
    ],
  },
  {
    id: 15,
    companyName: 'Amazon',
    protestColor: '#263692',
    event:
      "A petition organized by the American Civil Liberties Union's Washington branch, collected over 150,000 signatures from citizens concerned with the companies choice to provide facial surveillance technology to governments and law enforcement agencies through the 'Rekognition' program.",
    date: '06/11/2019',
    year: '2019',
    theme: 'Ethics(Rekognition)',
    protest: 'Ethics',
    how: 'Petition',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '150,000+',
    location: 'Internet',
    country: 'Internet',
    source: [
      'https://www.cnet.com/news/aclu-wants-amazon-to-stop-offering-surveillance-technology-rekognition/',
    ],
  },
  {
    id: 16,
    companyName: 'Facebook',
    protestColor: '#263692',
    event:
      'Protest against social media censorship is organized by conservatives following the election of Donald Trump.',
    date: '05/30/2019',
    year: '2019',
    theme: 'Ethics(Conservative)',
    protest: 'Ethics',
    how: 'Protest',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '',
    location: 'Menlo Park, California',
    country: 'USA',
    source: [
      'https://www.thegatewaypundit.com/2019/05/mass-protest-against-facebook-censorship-planned-at-annual-shareholder-meeting/',
    ],
  },
  {
    id: 17,
    companyName: 'Netflix',
    protestColor: '#263692',
    event:
      'After Netflix vowed to financially support the legal fight against a Georgia state law which would deem abortion unacceptable if the fetus had a heartbeat, protestors took to creating an online petition where users promised to cancel their subscription.',
    date: '05/30/2019',
    year: '2019',
    theme: 'Ethics(Abortion)',
    protest: 'Ethics',
    how: 'Petition(Online)',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '9720',
    location: 'Internet',
    country: 'Internet',
    source: [
      'https://www.citizengo.org/en-gb/lf/171041-stop-netflix-funding-pro-abortion-legal-protection-unborn-children-cancel-subscription',
    ],
  },
  {
    id: 18,
    companyName: 'Palantir',
    protestColor: '#263692',
    event:
      "Roughly 40 people flooded Palantir Technologies's Github page and 'flagged' issues - a function of the website which requires employees of Palantir Technologies to respond to each flagged problem. The demonstration was in response to the US Government's use of their technology to facilitate the detention and deportation of immigrants and unaccompanied children.",
    date: '05/14/2019',
    year: '2019',
    theme: 'Ethics(CBP, ICE)',
    protest: 'Ethics',
    how: 'Protest(Online)',
    actionDoneBy: 'Tech Employees',
    numberOfPeople: '40',
    location: 'Internet',
    country: 'Internet',
    source: [
      'https://www.theguardian.com/us-news/2019/may/13/tech-workers-palantir-immigration-protest-github',
    ],
  },
  {
    id: 19,
    companyName: 'Uber',
    protestColor: '#EE343D',
    event:
      "Uber and Lyft drivers participated in a global strike in response to unfair wages and exploitative pay practices. Central to the protest is continued criticisms of the companies' classifications of drivers as contractors rather than full-time employees. The strike was characterized by drivers turning of their apps for varying periods of time ranging from 2-hours to a full day.",
    date: '05/08/2019',
    year: '2019',
    theme: 'Labor / Wage',
    protest: 'Labor/Wage',
    how: 'Protest',
    actionDoneBy: 'Drivers',
    numberOfPeople: '',
    location: 'United States, Melbourne, Sydney, Montreal, London.',
    country: 'Worldwide',
    source: [
      'https://www.curbed.com/2019/5/7/18535700/uber-lyft-driver-strike-protest-ipo',
    ],
  },
  {
    id: 20,
    companyName: 'Oracle',
    protestColor: '#EE343D',
    event:
      'Oracle fires 900 of its staff from its China team. Protestors believe the firings to be politically motivated by the US-China trade war.',
    date: '05/07/2019',
    year: '2019',
    theme: 'Labor / Wage',
    protest: 'Labor/Wage',
    how: 'Protest',
    actionDoneBy: 'Workers',
    numberOfPeople: '24+',
    location: 'Beijing, China',
    country: 'China',
    source: ['https://www.ft.com/content/16ffdcec-749d-11e9-bbad-7c18c0ea0201'],
  },
  {
    id: 21,
    companyName: 'Google',
    protestColor: '#27AB4F',
    event:
      "Organizers of November 2018 walk-out organize a sit-in, 6-months to the date of the original protest in response to the company's perceived retaliation against those who report sexual harassment in the workplace.",
    date: '05/01/2019',
    year: '2019',
    theme: 'Sexual Harassment',
    protest: 'Sexual Harassment',
    how: 'Sit-In',
    actionDoneBy: 'Tech Employees',
    numberOfPeople: '',
    location: 'Worldwide',
    country: 'Worldwide',
    source: [
      'https://www.vice.com/en_us/article/8xzgjg/google-workers-sit-in-to-protest-alleged-retaliation-against-women-who-report-sexual-harassment',
    ],
  },
  {
    id: 22,
    companyName: 'Google',
    protestColor: '#EE343D',
    event:
      "Over 900 Google employees sign a letter in which they object to the company's treatment of temporary contractors, also known as temps, vendors and contractors (TVCs). The letter was spurred by events on March 8th, when 80% of a team of temporary contractors were told their contracts would end early.",
    date: '04/19/2019',
    year: '2019',
    theme: 'Labor / Wage',
    protest: 'Labor/Wage',
    how: 'Letter (Petition)',
    actionDoneBy: 'Tech Employees',
    numberOfPeople: '900+',
    location: 'Worldwide',
    country: 'Worldwide',
    source: [
      'https://www.theguardian.com/technology/2019/apr/02/google-workers-sign-letter-temp-contractors-protest',
    ],
  },
  {
    id: 23,
    companyName: 'Google',
    protestColor: '#27AB4F',
    event:
      'A letter demanding the removal of Kay Cole James from their Artificial Intelligence council is signed by thousands of Google employees. The letter accuses Kay Coles James as being "vocally anti-trans, anti-LGBTQ, and anti-immigrant."',
    date: '04/01/2019',
    year: '2019',
    theme: 'Gender Discrimination',
    protest: 'Discrimination',
    how: 'Letter (Petition)',
    actionDoneBy: 'Tech Employees',
    numberOfPeople: '2476',
    location: 'Internet',
    country: 'Internet',
    source: [
      'https://medium.com/@against.transphobia/googlers-against-transphobia-and-hate-b1b0a5dbf76',
    ],
  },
  {
    id: 24,
    companyName: 'Paypal',
    protestColor: '#263692',
    event:
      'An online petition demanding citizens of Afghanistan have access to PayPal gains over 5,000 signatures.',
    date: '03/07/2019',
    year: '2019',
    theme: 'Ethics (General)',
    protest: 'Ethics',
    how: 'Petition (Online)',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '5662',
    location: 'Afghanistan',
    country: 'Afghanistan',
    source: ['https://www.change.org/p/paypal-bring-paypal-to-afghanistan'],
  },
  {
    id: 25,
    companyName: 'Facebook',
    protestColor: '#263692',
    event:
      "Protestors stripped naked and gathered outside of Facebook's New York Headquarters as a result of Facebook's nudity policy. The protest was part of the 'Free the Nipple' movement.",
    date: '03/06/2019',
    year: '2019',
    theme: 'Ethics',
    protest: 'Ethics',
    how: 'Protest',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '24+',
    location: 'New York',
    country: 'USA',
    source: [
      'https://www.businessinsider.de/people-strip-off-in-protest-against-facebook-ban-on-nudity-2019-6?r=US&IR=T',
    ],
  },
  {
    id: 26,
    companyName: 'Paypal',
    protestColor: '#263692',
    event:
      "Boycott from conservative groups following news PayPal works with far-left 'Southern Poverty Law Centre' (SPLC) to identify and ban people from its platform.",
    date: '02/28/2019',
    year: '2019',
    theme: 'Ethics (Conservative)',
    protest: 'Ethics',
    how: 'Boycott',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '',
    location: 'Internet',
    country: 'Internet',
    source: [
      'https://www.foxnews.com/tech/conservatives-call-for-paypal-boycott-after-ceo-admits-splc-helps-ban-users',
    ],
  },
  {
    id: 27,
    companyName: 'HP',
    protestColor: '#EE343D',
    event:
      'In September 2008, HP announced it was cutting 7.5% of its workforce. Thousands of these jobs were in Europe and number of demonstrations took place across the continent to protest the layoffs and working conditions.',
    date: '02/10/2019',
    year: '2019',
    theme: 'Labor / Wage',
    protest: 'Labor/Wage',
    how: 'Protest',
    actionDoneBy: 'Workers',
    numberOfPeople: '',
    location: 'Germany',
    country: 'Germany',
    source: [
      'https://www.eurofound.europa.eu/publications/article/2009/europe-wide-protests-against-hewlett-packard-job-cuts',
    ],
  },
  {
    id: 28,
    companyName: 'Amazon',
    protestColor: '#263692',
    event:
      'Protestors interrupt a New York City Council Finance Committee hearing, demanding the HQ2 project not go forward.',
    date: '01/30/2019',
    year: '2019',
    theme: 'Ethics',
    protest: 'Ethics',
    how: 'Protest',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '',
    location: 'Manhattan, New York',
    country: 'USA',
    source: [
      'https://www.vox.com/the-goods/2019/2/14/18225003/amazon-hq2-new-york-pulling-out',
    ],
  },
  {
    id: 29,
    companyName: 'Google',
    protestColor: '#27AB4F',
    event:
      'Google employees launch a campaign called "Googlers for Ending Forced Arbitration"',
    date: '01/19/2019',
    year: '2019',
    theme: 'Forced Arbitration',
    protest: 'Forced Arbitration',
    how: 'Campaign Launch',
    actionDoneBy: 'Tech Employees',
    numberOfPeople: '',
    location: 'Worldwide',
    country: 'Worldwide',
    source: [
      'https://www.nbcnews.com/tech/tech-news/google-employees-launch-campaign-bring-discrimination-issues-out-shadows-n959016',
    ],
  },
  {
    id: 30,
    companyName: 'Amazon',
    protestColor: '#263692',
    event:
      "In response to Amazon's announcement of plans to locate two new headquarters in New York, protestors gathered outside of a public hearings for the project at the New York City Council",
    date: '12/28/2018',
    year: '2018',
    theme: 'Ethics',
    protest: 'Ethics',
    how: 'Protest',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '50+',
    location: 'Manhattan, New York',
    country: 'USA',
    source: [
      'https://www.vox.com/2018/12/12/18137488/new-york-amazon-hq2-deal-hearing',
    ],
  },
  {
    id: 31,
    companyName: 'Google',
    protestColor: '#27AB4F',
    event:
      'Organizers of the November 2018 Google walk-out demanded an end to forced arbitration. Following the walk-out, Google only allowed arbitration for sexual harassment and assault cases for full-time employees and not contract workers. Feeling the response to be insufficient, organizers of the previous walk-out drafted a letter demanding an end to arbitration for all employees and all forms of labor disputes. The letter also called for the support of other employees working in the tech industry.',
    date: '12/10/2018',
    year: '2018',
    theme: 'Forced Arbitration',
    protest: 'Forced Arbitration',
    how: 'Letter',
    actionDoneBy: 'Tech Employees',
    numberOfPeople: '20',
    location: 'Worldwide',
    country: 'Worldwide',
    source: [
      'https://www.businessinsider.com/google-walkout-forced-arbitration-2018-12?r=UK&IR=T',
    ],
  },
  {
    id: 32,
    companyName: 'Google',
    protestColor: '#263692',
    event:
      'A second letter demanding Google drop project Dragonfly is signed by hundreds of Google employees.',
    date: '11/27/2018',
    year: '2018',
    theme: 'Ethics (Dragonfly)',
    protest: 'Ethics',
    how: 'Letter (Petition)',
    actionDoneBy: 'Tech Employees',
    numberOfPeople: '740',
    location: 'Worldwide',
    country: 'Worldwide',
    source: [
      'https://medium.com/@googlersagainstdragonfly/we-are-google-employees-google-must-drop-dragonfly-4c8a30c5e5eb',
    ],
  },
  {
    id: 33,
    companyName: 'Google',
    protestColor: '#263692',
    event:
      "Amnesty International protests outside of Google offices worldwide in response to Chinese censorship program 'Dragonfly'",
    date: '11/27/2018',
    year: '2018',
    theme: 'Ethics (Dragonfly)',
    protest: 'Ethics',
    how: 'Protests (Multiple)',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '',
    location: 'Worldwide',
    country: 'Worldwide',
    source: [
      'https://www.theglobeandmail.com/business/article-canadian-google-offices-set-to-face-protests-from-activists-over/',
    ],
  },
  {
    id: 34,
    companyName: 'Amazon',
    protestColor: '#263692',
    event:
      "In response to Amazon's announcement of plans to locate two new headquarters in New York, protestors gathered in Court House Square, (Queens, New York).",
    date: '11/26/2018',
    year: '2018',
    theme: 'Ethics',
    protest: 'Ethics',
    how: 'Protests',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '24+',
    location: 'Queens, New York',
    country: 'USA',
    source: [
      'https://www.vox.com/2019/1/30/18202825/amazon-hq2-new-york-city-political-battle-de-blasio-queens',
    ],
  },
  {
    id: 35,
    companyName: 'Amazon',
    protestColor: '#EE343D',
    event:
      "Across 5 locations in the UK, Amazon warehouse workers walked out in protest of 'inhumane' working conditions. The protest took place on Black Friday, one of the busiest days of the year.",
    date: '11/23/2018',
    year: '2018',
    theme: 'Labor / Wage',
    protest: 'Labor/Wage',
    how: 'Protest',
    actionDoneBy: 'Workers',
    numberOfPeople: '300',
    location: 'Rugeley, Swansea, Peterborough, Milton Keyes, Warrington',
    country: 'UK',
    source: [
      'https://www.telegraph.co.uk/technology/2018/11/24/union-stages-protest-against-amazon-robot-working-conditions/',
    ],
  },
  {
    id: 36,
    companyName: 'Amazon',
    protestColor: '#263692',
    event:
      "Shareholders brought 9 proposals to the proxy ballot at Amazon's annual shareholder meeting. Some of the concerns outlined include: the sale of Amazon's 'Rekognition' technology to governments and law enforcement and the companies failure to remove racist and offensive products from its marketplace. The statement was ultimately endorsed by 114 investors who together represent more that $ 2.6 trillion USD in assets. The statement concludes, that Amazon does not presently have adequate risk mitigation structures in place.",
    date: '11/05/2018',
    year: '2018',
    theme: 'Ethics (Rekognition)',
    protest: 'Ethics',
    how: 'Shareholder Proposal',
    actionDoneBy: 'Shareholders',
    numberOfPeople: '114',
    location: 'USA',
    country: 'USA',
    source: [
      'https://www.iccr.org/shareholders-present-slate-proposals-amazon-annual-meeting',
      'https://www.iccr.org/sites/default/files/page_attachments/amzn_investor_letter_draft_final_no_signatures-_nov_5_2018.pdf',
    ],
  },
  {
    id: 37,
    companyName: 'Facebook',
    protestColor: '#263692',
    event:
      "Brooklyn high school students protest their school's adoption of program designed by facebook engineers and paid for by CEO Mark Zuckerberg.",
    date: '11/05/2018',
    year: '2018',
    theme: 'Ethics',
    protest: 'Ethics',
    how: 'Protest',
    actionDoneBy: 'Students',
    numberOfPeople: '100+',
    location: 'Brooklyn, New York',
    country: 'USA',
    source: [
      'https://nypost.com/2018/11/10/brooklyn-students-hold-walkout-in-protest-of-facebook-designed-online-program/amp/',
    ],
  },
  {
    id: 38,
    companyName: 'Amazon',
    protestColor: '#939598',
    event:
      "Around 450 antique book dealers spread across 26 countries pulled their books off the site of an Amazon subsidiary in order to protest the company's choice to drop book dealers from a select group of nations. The action was responsible for removing 4-million books from the AbeBooks service.",
    date: '11/04/2018',
    year: '2018',
    theme: 'Company Policy',
    protest: 'Company Policy',
    how: 'Protest (Online)',
    actionDoneBy: 'Vendors',
    numberOfPeople: '450+',
    location: 'Worldwide',
    country: 'Worldwide',
    source: [
      'https://www.seattletimes.com/business/booksellers-hit-amazon-with-strike/',
    ],
  },
  {
    id: 39,
    companyName: 'Google',
    protestColor: '#EE343D',
    event:
      'Estimated 20,000 Google employees walked out in protest of two senior executives at the company having been paid tens of millions of dollars despite being accused of sexual misconduct.',
    date: '11/01/2018',
    year: '2018',
    theme: 'Sexual Harassment',
    protest: 'Sexual Harassment',
    how: 'Walk-out',
    actionDoneBy: 'Tech Employees',
    numberOfPeople: '20,000',
    location: 'Worldwide',
    country: 'Worldwide',
    source: [
      'https://www.nbcnews.com/tech/tech-news/google-employees-begin-walkout-over-handling-sexual-misconduct-executives-n929696?cid=ed_npd_bn_tw_bn',
    ],
  },
  {
    id: 40,
    companyName: 'Amazon',
    protestColor: '#263692',
    event:
      "An anonymous group of Amazon employees urged Jeff Bezos and ranking executives not sell facial recognition software (part of the program 'Recognition') to police. The letter also demanded Amazon remove Palantir from Amazon Web Services because of their involvement with ICE's deportation and tracking program.",
    date: '10/16/2018',
    year: '2018',
    theme: 'Ethics (ICE)',
    protest: 'Ethics',
    how: 'Letter (Anonymous)',
    actionDoneBy: 'Tech Employees',
    numberOfPeople: '450+',
    location: 'Internet',
    country: 'Internet',
    source: [
      'https://medium.com/@amazon_employee/im-an-amazon-employee-my-company-shouldn-t-sell-facial-recognition-tech-to-police-36b5fde934ac',
    ],
  },
  {
    id: 41,
    companyName: 'Microsoft',
    protestColor: '#263692',
    event:
      "A group of Microsoft employees (who chose to remain anonymous), demanded the company not to bid on the $10 billion USD project with the Defense Department known as 'Joint Enterprise Defense Infrastructure' (JEDI).",
    date: '10/13/2018',
    year: '2018',
    theme: 'Ethics (JEDI)',
    protest: 'Ethics',
    how: 'Letter (Anonymous)',
    actionDoneBy: 'Tech Employees',
    numberOfPeople: '',
    location: 'Internet',
    country: 'Internet',
    source: [
      'https://thehill.com/policy/technology/411503-microsoft-employees-pressure-company-to-not-pursue-multi-billion-pentagon',
    ],
  },
  {
    id: 42,
    companyName: 'Salesforce',
    protestColor: '#263692',
    event:
      "Roughly a dozen protestors gathered to protest the company's contract with US Customs and Border Patrol (CBP).",
    date: '09/25/2018',
    year: '2018',
    theme: 'Ethics (CBP)',
    protest: 'Ethics',
    how: 'Protest',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '12',
    location: 'San Francisco',
    country: 'USA',
    source: [
      'https://techcrunch.com/2018/09/25/protestors-call-on-salesforce-to-end-contract-with-border-patrol-agency/',
    ],
  },
  {
    id: 43,
    companyName: 'Google',
    protestColor: '#263692',
    event:
      "Following leaked documents from the secret 'Dragonfly' project, a censored search engine for the Chinese government, Google employees circulated an open letter demanding greater transparency. The letter contained a list of demands which included: 1) introduction of ethics review structure, 2) appointment of ombudspeople, 3) clear plan for transparency, and 4) ethical assessment of Dragonfly, Maven & Air Gap GCP.",
    date: '08/16/2018',
    year: '2018',
    theme: 'Ethics (Dragonfly, Maven, Air Gap GCP)',
    protest: 'Ethics',
    how: 'Letter (Petition)',
    actionDoneBy: 'Tech Employees',
    numberOfPeople: '1400+',
    location: 'Worldwide',
    country: 'Worldwide',
    source: [
      'https://www.buzzfeednews.com/article/carolineodonovan/google-dragonfly-maven-employee-protest-demands',
    ],
  },
  {
    id: 44,
    companyName: 'Facebook',
    protestColor: '#263692',
    event:
      'Belgian art officials drafted and signed an open letter to Mark Zuckerberg, demanding that artistic representations of nudity be allowed to be displayed on their platform.',
    date: '07/30/2018',
    year: '2018',
    theme: 'Ethics',
    protest: 'Ethics',
    how: 'Petition',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '12+',
    location: 'Belgium',
    country: 'Belgium',
    source: [
      'https://mashable.com/2018/07/30/belgian-museums-facebook-artisitic-nudity/?europe=true',
    ],
  },
  {
    id: 45,
    companyName: 'Apple',
    protestColor: '#EE343D',
    event:
      'Dozens of activists staged an elaborate protest by transforming an Apple store into an emergency ward (equipped with x-rays, waiting room, surgeons and bloodied patients). The protest was in response to tax evasion by US tech companies operating in the EU.',
    date: '06/30/2018',
    year: '2018',
    theme: 'Tax Evasion',
    protest: 'Tax Evasion',
    how: 'Street Protest',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '24',
    location: 'Paris,France',
    country: 'France',
    source: ['https://www.rt.com/news/431395-france-protest-apple-store/'],
  },
  {
    id: 46,
    companyName: 'Salesforce',
    protestColor: '#263692',
    event:
      'Over 650 Salesforce employees have signed a petition asking the company to terminate its contract with US Customs and Border Protection (CBP).',
    date: '06/25/2018',
    year: '2018',
    theme: 'Ethics (CBP)',
    protest: 'Ethics',
    how: 'Petition',
    actionDoneBy: 'Tech Employees',
    numberOfPeople: '650+',
    location: 'Internet',
    country: 'Internet',
    source: [
      'https://techcrunch.com/2018/09/25/protestors-call-on-salesforce-to-end-contract-with-border-patrol-agency/',
    ],
  },
  {
    id: 47,
    companyName: 'Microsoft',
    protestColor: '#263692',
    event:
      "Microsoft employees wrote an open letter expressing their anger over the company's choice to work with Immigration and Customs Enforcement (ICE).",
    date: '06/21/2018',
    year: '2018',
    theme: 'Ethics (ICE)',
    protest: 'Ethics',
    how: 'Petition',
    actionDoneBy: 'Tech Employees',
    numberOfPeople: '300+',
    location: 'Internet',
    country: 'Internet',
    source: [
      'https://www.theverge.com/2018/6/21/17488328/microsoft-ice-employees-signatures-protest',
    ],
  },
  {
    id: 48,
    companyName: 'Facebook',
    protestColor: '#263692',
    event:
      "Facebook shareholders protest against the company's operation, with 1/4 of stakeholders voting against the reappointment of Mark Zuckerberg and Sheryl Sandberg, and 60% of Class A stakeholders (or, 'ordinary stakeholder') voting for a 'one vote per share' system - a jab at the current existing power structure where Mark Zuckerberg controls 51% of the votes while owning 14% of the company.",
    date: '06/05/2018',
    year: '2018',
    theme: 'Company Management',
    protest: 'Company Policy',
    how: 'Shareholder Proposal',
    actionDoneBy: 'Shareholders',
    numberOfPeople: '',
    location: 'Menlo Park, California',
    country: 'USA',
    source: [
      'https://www.irishtimes.com/business/technology/facebook-investors-lodge-protest-vote-against-board-1.3521069',
    ],
  },
  {
    id: 49,
    companyName: 'Amazon',
    protestColor: '#EE343D',
    event:
      "Amazon workers from Spain, Poland and Germany, protested outside of the Axel Springer headquarters while Amazon CEO Jeff Bezos received and award inside. Protestors demanded wage regulations within Amazon's German logistic centers.",
    date: '06/05/2018',
    year: '2018',
    theme: 'Labor / Wage',
    protest: 'Labor/Wage',
    how: 'Protest',
    actionDoneBy: 'Workers',
    numberOfPeople: '450',
    location: 'Berlin, Germany',
    country: 'Germany',
    source: [
      'https://www.dw.com/en/jeff-bezos-at-berlin-prize-ceremony-greeted-by-protests-against-amazon/a-43517761',
    ],
  },
  {
    id: 50,
    companyName: 'Google',
    protestColor: '#263692',
    event: 'A dozen employees resign from Google in response to project Maven.',
    date: '05/14/2018',
    year: '2018',
    theme: 'Ethics (Maven)',
    protest: 'Ethics',
    how: 'Protest (Multiple Resignations)',
    actionDoneBy: 'Tech Employees',
    numberOfPeople: '12',
    location: 'USA',
    country: 'USA',
    source: [
      'https://gizmodo.com/google-employees-resign-in-protest-against-pentagon-con-1825729300',
    ],
  },
  {
    id: 51,
    companyName: 'Google',
    protestColor: '#263692',
    event:
      'Thousands of Google employees sign a petition demanding the company take a clear stance that it would never build warfare technology. Petition was in response to the project Maven, a collaborative effort with the Pentagon to use artificial intelligence to enhance drone strikes.',
    date: '05/14/2018',
    year: '2018',
    theme: 'Ethics (Maven)',
    protest: 'Ethics',
    how: 'Letter (Petition)',
    actionDoneBy: 'Tech Employees',
    numberOfPeople: '4,000+',
    location: 'Internet',
    country: 'Internet',
    source: [
      'https://gizmodo.com/google-employees-resign-in-protest-against-pentagon-con-1825729300',
    ],
  },
  {
    id: 52,
    companyName: 'HP',
    protestColor: '#263692',
    event:
      'Protestors demonstrated against Hewlett Packards support of the Israeli Military and the Israeli Apartheid in Berlin',
    date: '03/03/2018',
    year: '2018',
    theme: 'Ethics (Palestine)',
    protest: 'Ethics',
    how: 'Protest',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '20',
    location: 'Berlin, Germany',
    country: 'Germany',
    source: [
      'https://samidoun.net/2018/03/3-march-berlin-protest-against-hewlett-packard/',
    ],
  },
  {
    id: 53,
    companyName: 'Google',
    protestColor: '#263692',
    event:
      'Former employees of Facebook and Google launch campaign "Truth About Tech" to fight against what they built.',
    date: '02/04/2018',
    year: '2018',
    theme: 'Ethics (General)',
    protest: 'Ethics',
    how: 'Campaign Launch',
    actionDoneBy: 'Tech Employees',
    numberOfPeople: '',
    location: 'USA',
    country: 'USA',
    source: [
      'https://www.business-humanrights.org/en/usa-former-facebook-google-employees-form-coalition-to-fight-dangerous-effects-of-technology',
    ],
  },
  {
    id: 54,
    companyName: 'Netflix',
    protestColor: '#263692',
    event:
      "Following the release of comedian Tom Segura's Netflix special 'Disgraceful', an online petition was started to demanded the comedy special be taken down due to its anti-down syndrome rhetoric.",
    date: '01/22/2018',
    year: '2018',
    theme: 'Ethics (General)',
    protest: 'Ethics',
    how: 'Petition (Online)',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '97,071',
    location: 'Internet',
    country: 'Internet',
    source: ['https://www.change.org/p/netflix-take-it-down-netflix'],
  },
  {
    id: 55,
    companyName: 'HP',
    protestColor: '#263692',
    event:
      'Protestors demonstrated against Hewlett Packards support of the Israeli Military and the Israeli Apartheid in Berlin',
    date: '11/29/2017',
    year: '2017',
    theme: 'Ethics (Palestine)',
    protest: 'Ethics',
    how: 'Protest',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '57',
    location: 'Berlin, Germany',
    country: 'Germany',
    source: [
      'https://samidoun.net/2017/11/29-november-berlin-protest-against-hewlett-packards-dealings-with-apartheid/',
    ],
  },
  {
    id: 56,
    companyName: 'Apple',
    protestColor: '#EE343D',
    event:
      "Roughly 100 protestors from AT&T protested an Apple product launch event at Apple's campus. The protest was in response to a lack of job security following mass outsourcing my AT&T",
    date: '09/12/2017',
    year: '2017',
    theme: 'Job Security',
    protest: 'Job Security',
    how: 'Street Protest',
    actionDoneBy: 'AT&T Workers',
    numberOfPeople: '100',
    location: 'Cupertino, California',
    country: 'USA',
    source: [
      'http://www.siliconbeat.com/2017/09/11/att-workers-plans-protest-apples-iphone-launch-event/',
    ],
  },
  {
    id: 57,
    companyName: 'Adobe',
    protestColor: '#263692',
    event:
      "In light of Adobe Systems's decision to discontinue Flash Player, consumers and developers who work with the software created a petition to demand the program be released as open-source software.",
    date: '07/28/2017',
    year: '2017',
    theme: 'Consumer Outrage',
    protest: 'Consumer Outrage',
    how: 'Petition (Online)',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '21,855',
    location: 'Internet',
    country: 'Internet',
    source: [
      'https://www.change.org/p/adobe-systems-make-flash-player-open-sourced',
    ],
  },
  {
    id: 58,
    companyName: 'HP',
    protestColor: '#263692',
    event:
      "Activists protest HP's involvement in the Israeli apartheid and the companies profit from US policies regarding immigration and incarceration. The demonstration took place outside the RSA cyber-security conference in San Francisco.",
    date: '02/19/2017',
    year: '2017',
    theme: 'Ethics (Palestine, Immigration, Incarceration)',
    protest: 'Ethics',
    how: 'Protest',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '',
    location: 'San Francisco',
    country: 'USA',
    source: ['https://www.indybay.org/newsitems/2017/02/19/18796636.php'],
  },
  {
    id: 59,
    companyName: 'Uber',
    protestColor: '#EE343D',
    event:
      "Following Uber's choice to cut prices by 15% - 20% for passengers, hundreds of drivers found themselves making significantly lower returns and chose to organize and strike.",
    date: '02/13/2017',
    year: '2017',
    theme: 'Labor / Wage',
    protest: 'Labor/Wage',
    how: 'Protest',
    actionDoneBy: 'Drivers',
    numberOfPeople: '200+',
    location: 'Qatar',
    country: 'Qatar',
    source: [
      'https://www.reuters.com/article/us-uber-qatar-strike/hundreds-of-uber-drivers-in-qatar-go-on-strike-after-price-cuts-idUSKBN15S196',
    ],
  },
  {
    id: 60,
    companyName: 'Oracle',
    protestColor: '#263692',
    event:
      "Petition demanding that Oracle sign the 'Immigrant Amiscus Brief', opposing President Trump's executive order on immigration.",
    date: '02/12/2017',
    year: '2017',
    theme: '',
    protest: 'Other',
    how: 'Petition (Online)',
    actionDoneBy: 'Tech Employees',
    numberOfPeople: '930',
    location: 'Internet',
    country: 'Internet',
    source: [''],
  },
  {
    id: 61,
    companyName: 'IBM',
    protestColor: '#263692',
    event:
      'An open letter written by IBM employees and addressed to CEO Ginni Rometty, demanded the company reaffirm its values and acknowledge the right of employees to refuse participation in US government contracts which violate civil liberties.',
    date: '02/12/2017',
    year: '2017',
    theme: '',
    protest: 'Other',
    how: 'Petition (Online)',
    actionDoneBy: 'Tech Employees',
    numberOfPeople: '2,736',
    location: 'Internet',
    country: 'Internet',
    source: [
      'https://www.coworker.org/petitions/ibmers-to-ceo-ginni-rometty-affirm-ibm-values',
    ],
  },
  {
    id: 62,
    companyName: 'Uber',
    protestColor: '#263692',
    event:
      '#DeleteUber protest leads to over 200,000 accounts being deleted over the course of 6 days.',
    date: '02/03/2017',
    year: '2017',
    theme: '',
    protest: 'Other',
    how: 'Protest',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '200,000+',
    location: 'Worldwide',
    country: 'Worldwide',
    source: [
      'https://www.theverge.com/2017/2/2/14493760/delete-uber-protest-donald-trump-accounts-deleted',
    ],
  },
  {
    id: 63,
    companyName: 'Apple',
    protestColor: '#231F20',
    event:
      'Following a number of protests intended to stop private buses operating in Silicon Valley, five buses used by Apple to shuttle their employees have their windows shattered by pellet guns.',
    date: '01/17/2017',
    year: '2017',
    theme: 'Tech Presence',
    protest: 'Tech Presence',
    how: 'Protest',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '',
    location: 'San Francisco',
    country: 'USA',
    source: [
      'https://www.cnet.com/news/apple-employee-commuter-buses-attacked-san-francisco-silicon-valley/',
    ],
  },
  {
    id: 64,
    companyName: 'Oracle',
    protestColor: '#263692',
    event:
      "George A Bolisner, posted a public resignation letter to LinkedIn because he did not agree with Company's choice to aid President Trump.",
    date: '12/19/2016',
    year: '2016',
    theme: 'Public Resignation',
    protest: 'Public Resignation',
    how: 'Resignation',
    actionDoneBy: 'Tech Employee',
    numberOfPeople: '1',
    location: 'Redwood City, California',
    country: 'USA',
    source: [
      'https://www.linkedin.com/pulse/resigning-from-oracle-george-a-polisner/',
    ],
  },
  {
    id: 65,
    companyName: 'HP',
    protestColor: '#263692',
    event:
      'Between November 25 - December 3, more than 150 demonstrations took place in 30 different countries. This mass mobilization was in support of Palestine, protesting the use of HP technology by the Israeli Army.',
    date: '11/25/2016',
    year: '2016',
    theme: 'Ethics (Palestine)',
    protest: 'Ethics',
    how: 'Protests (Multiple)',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '',
    location: 'Worldwide',
    country: 'Worldwide',
    source: [
      'https://www.workers.org/2016/12/17/largest-bds-mobilization-ever-boycott-hewlett-packard/',
    ],
  },
  {
    id: 66,
    companyName: 'HP',
    protestColor: '#263692',
    event:
      "'Boycott HP International Week of Action' - For one week demonstrations took place across 6 continents in over a 100 countries, demanding HP discontinue its support of the Israeli apartheid.",
    date: '11/25/2016',
    year: '2016',
    theme: 'Ethics (Palestine)',
    protest: 'Ethics',
    how: 'Petition (Online)',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '900+',
    location: 'Worldwide',
    country: 'Worldwide',
    source: ['https://www.facebook.com/events/112884545846474/'],
  },
  {
    id: 67,
    companyName: 'HP',
    protestColor: '#263692',
    event:
      'An online petition calling for Hewlett Packard to stop supporting the Israeli military and apartheid by providing necessary technology gathers over 10,000 signatures.',
    date: '11/25/2016',
    year: '2016',
    theme: 'Ethics (Palestine)',
    protest: 'Ethics',
    how: 'Petition (Online)',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '10,097',
    location: 'Internet',
    country: 'Internet',
    source: [
      'https://org.salsalabs.com/o/641/p/dia/action3/common/public/?action_KEY=20604',
    ],
  },
  {
    id: 68,
    companyName: 'Paypal',
    protestColor: '#263692',
    event: 'Boycott after CEO donates million to Trump campaign',
    date: '10/17/2016',
    year: '2016',
    theme: 'Ethics (General)',
    protest: 'Ethics',
    how: 'Boycott',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '',
    location: 'Internet',
    country: 'Internet',
    source: [
      'https://www.telegraph.co.uk/technology/2016/10/17/paypal-founder-peter-thiels-1m-trump-donation-leads-to-boycott-c/',
    ],
  },
  {
    id: 69,
    companyName: 'Paypal',
    protestColor: '#263692',
    event:
      "An online petition demanding citizens of Gaza have access to PayPal gains nearly 200,000 signatures. The petition alleges that company does not acknowledge Palestine's sovereignty.",
    date: '09/13/2016',
    year: '2016',
    theme: 'Ethics (Palestine)',
    protest: 'Ethics',
    how: 'Petition (Online)',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '176,422',
    location: 'Palestine',
    country: 'Palestine',
    source: [
      'https://actions.sumofus.org/a/paypal-stop-discriminating-against-palestinians',
    ],
  },
  {
    id: 70,
    companyName: 'Netflix',
    protestColor: '#263692',
    event:
      'After Netflix decided to block certain VPN services so that users of these services could not access the US catalogue of its services, a petition was circulated which drew attention the negative precedent this policy sets towards a users right to data privacy.',
    date: '05/11/2016',
    year: '2016',
    theme: 'Ethics (Data Privacy)',
    protest: 'Ethics',
    how: 'Protest (Online)',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '50,000',
    location: 'Internet',
    country: 'Internet',
    source: ['https://act.openmedia.org/netflix'],
  },
  {
    id: 71,
    companyName: 'Intel',
    protestColor: '#263692',
    event:
      'An online petition demands that Intel must pull financial support of the boy scouts until they reverse their anti-gay policy',
    date: '04/06/2016',
    year: '2016',
    theme: 'Ethics (LGBTQ)',
    protest: 'Ethics',
    how: 'Petition (Online)',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '31,362',
    location: 'Internet',
    country: 'Internet',
    source: [
      'https://www.change.org/p/intel-corporation-pull-your-financial-support-until-the-boy-scouts-pull-their-anti-gay-policy',
    ],
  },
  {
    id: 72,
    companyName: 'Uber',
    protestColor: '#EE343D',
    event:
      "Hundreds of Uber drivers protest outside the company's office in Bengaluru after reports that when drivers made a complaint, they would shut off from the app.",
    date: '09/23/2015',
    year: '2015',
    theme: 'Labor / Wage',
    protest: 'Labor/Wage',
    how: 'Protest',
    actionDoneBy: 'Drivers',
    numberOfPeople: '600',
    location: 'Bengaluru, India',
    country: 'India',
    source: [
      'https://timesofindia.indiatimes.com/city/bengaluru/600-odd-Uber-drivers-protest-in-Bengaluru/articleshow/49087925.cms',
    ],
  },
  {
    id: 73,
    companyName: 'Facebook',
    protestColor: '#263692',
    event:
      "Roughly 70 people protested Facebook's real-name policy during the San Francisco Pride Parade. Demonstrators argue the policy discriminates against LGBTQ, intersex people and gender-fluid people. The movement also highlights how using one's real name does not necessarily make the community safer, as victims of abuse are often hiding from their abusers.",
    date: '06/28/2015',
    year: '2015',
    theme: 'Ethics (LGBTQ)',
    protest: 'Ethics',
    how: 'Protest',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '70',
    location: 'San Francisco',
    country: 'USA',
    source: [
      'http://www.siliconbeat.com/2015/06/29/drag-queens-and-lgbt-community-protest-facebooks-real-name-policy-at-sf-pride-parade/',
    ],
  },
  {
    id: 74,
    companyName: 'Facebook',
    protestColor: '#263692',
    event:
      "Approximately 60 people protested outside of Facebook's headquarters against the company's real-name policy, which demonstrators argue discriminates against LGBTQ, intersex people and gender-fluid people. The movement also highlights how using one's real name does not necessarily make the community safer, as victims of abuse are often hiding from their abusers.",
    date: '06/02/2015',
    year: '2015',
    theme: 'Ethics (LGBTQ)',
    protest: 'Ethics',
    how: 'Protest',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '60',
    location: 'Menlo Park, California',
    country: 'USA',
    source: ['https://www.bbc.com/news/blogs-trending-32961249'],
  },
  {
    id: 75,
    companyName: 'Facebook',
    protestColor: '#263692',
    event:
      "Thousands of people in India deactivated their Facebook account for one day in order to protest Mark Zuckerberg's stance on Net Neutrality",
    date: '04/24/2015',
    year: '2015',
    theme: 'Ethics (Net-Neutrality)',
    protest: 'Ethics',
    how: 'Protest (Online)',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '29,000',
    location: 'Internet',
    country: 'Internet',
    source: [
      'https://www.techinasia.com/net-neutrality-why-india-dislikes-zuckerberg-airtel',
    ],
  },
  {
    id: 76,
    companyName: 'Spotify',
    protestColor: '#263692',
    event:
      "16 Musicians (later 23), led by Jay-Z, to denounce the low percentage artists were receiving for their work through the popular music streaming service Spotify. They create the music streaming service 'Tidal' in response to what they saw as the unfair reality behind being paid for your work.",
    date: '03/30/2015',
    year: '2015',
    theme: 'Ethics (General)',
    protest: 'Ethics',
    how: 'Disrupting the Monopoly',
    actionDoneBy: 'Musicians and Artists',
    numberOfPeople: '23',
    location: 'USA',
    country: 'USA',
    source: [
      'https://www.billboard.com/articles/news/6509498/jay-z-tidal-launch-artist-stakeholders',
    ],
  },
  {
    id: 77,
    companyName: 'Facebook',
    protestColor: '#263692',
    event:
      "Following Facebook's new policy for users to operate their account under their real name, thousands of people sign a petition that demand facebook revert the rule due to the fact that it discriminates against drag-queens, drag-kings, LGBTQ, intersex and gender-fluid users. The campaign is know as '#MyNameIs' movement.",
    date: '09/13/2014',
    year: '2014',
    theme: 'Ethics (LGBTQ)',
    protest: 'Ethics',
    how: 'Petition (Online)',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '46',
    location: 'Internet',
    country: 'Internet',
    source: [
      'https://www.change.org/p/facebook-allow-performers-to-use-their-stage-names-on-their-facebook-accounts',
    ],
  },
  {
    id: 78,
    companyName: 'Apple',
    protestColor: '#EE343D',
    event:
      "50 protestors blocked main entrance of Apple's flagship store in San Francisco. The protest was spurred by service employees claiming they lack job security and are underpaid.",
    date: '08/28/2014',
    year: '2014',
    theme: 'Job Security',
    protest: 'Job Security',
    how: 'Street Protest (Picket line)',
    actionDoneBy: 'Service employees (security guards) and concerned citizens',
    numberOfPeople: '50',
    location: 'San Francisco',
    country: 'USA',
    source: [
      'https://www.cultofmac.com/293228/apples-security-guards-stage-protest-outside-san-francisco-apple-store/',
    ],
  },
  {
    id: 79,
    companyName: 'Google',
    protestColor: '#EE343D',
    event:
      "A proposal was submitted at Google's annual meeting with shareholders which articulates the importance of reformulating the companies approach to taxes. The proposal states that the company should 'adopt a set of principles to address the impact of Google's tax strategies on society'.",
    date: '05/14/2014',
    year: '2014',
    theme: 'Tax Evasion',
    protest: 'Tax Evasion',
    how: 'Shareholder Proposal',
    actionDoneBy: 'Shareholders',
    numberOfPeople: '',
    location: 'USA',
    country: 'USA',
    source: [''],
  },
  {
    id: 80,
    companyName: 'Paypal',
    protestColor: '#263692',
    event:
      'An online petition demanding citizens of Ghana have access to PayPal gains over 10,000 signatures.',
    date: '09/03/2013',
    year: '2013',
    theme: 'Ethics (General)',
    protest: 'Ethics',
    how: 'Petition (Online)',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '13,778',
    location: 'Ghana',
    country: 'Ghana',
    source: ['https://www.change.org/p/rupert-keeley-enable-paypal-in-ghana'],
  },
  {
    id: 81,
    companyName: 'Adobe',
    protestColor: '#263692',
    event:
      "Users of Adobe software demand the company not make its software only available via the 'Creative Cloud' package",
    date: '05/06/2013',
    year: '2013',
    theme: 'Consumer Outrage',
    protest: 'Consumer Outrage',
    how: 'Petition (Online)',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '50,131',
    location: 'Internet',
    country: 'Internet',
    source: [
      'https://www.change.org/p/adobe-systems-incorporated-eliminate-the-mandatory-creative-cloud-subscription-model',
      'https://sites.google.com/a/dcsdk12.org/msclaus/products-services/illustrator-project-1/adobe-cc-protest-research',
    ],
  },
  {
    id: 82,
    companyName: 'Paypal',
    protestColor: '#263692',
    event:
      "Protest after 'obscene' e-book policy, as those speaking out against it felt it was an act of censorship",
    date: '03/13/2013',
    year: '2013',
    theme: 'Ethics (General)',
    protest: 'Ethics',
    how: 'Protest (Online)',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '',
    location: 'Internet',
    country: 'Internet',
    source: [
      'https://www.reuters.com/article/us-paypal/exclusive-paypal-backtracks-on-obscene-e-book-policy-idUSBRE82C11C20120313',
    ],
  },
  {
    id: 83,
    companyName: 'Apple',
    protestColor: '#231F20',
    event:
      'Roughly 250,000 signatures collected through separate petitions on SumOfUs.org and Change.org were printed, stacked, and delivered to stores in Washington D. C., San Francisco, London, Sydney, Bangalore and New York City. The petitions were in response to reports of dangerous working conditions and suicides at Foxconn plants in China.',
    date: '02/09/2012',
    year: '2012',
    theme: 'Foreign Working Conditions',
    protest: 'Foreign Working Conditions',
    how: 'Petition (Online)',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '250,000',
    location: 'Internet',
    country: 'Internet',
    source: ['https://www.huffpost.com/entry/apple-store-protests_n_1265406'],
  },
  {
    id: 84,
    companyName: 'Apple',
    protestColor: '#231F20',
    event:
      "Apple customers respond to recent allegations against the company concerning dangerous working conditions of workers in its supply chain. In particular, it was influenced by revelations of poor working conditions and a string of suicides at Foxconn's manufacturing plants in China, a major producer of components for the iPhone.",
    date: '01/31/2012',
    year: '2012',
    theme: 'Foreign Working Conditions',
    protest: 'Foreign Working Conditions',
    how: 'Petition (Online)',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '40,000',
    location: 'Internet',
    country: 'Internet',
    source: [
      'https://www.cnet.com/news/petition-tells-apple-we-want-an-ethical-iphone-5/',
    ],
  },
  {
    id: 85,
    companyName: 'Apple',
    protestColor: '#231F20',
    event:
      '150 Chinese workers threaten to commit mass-suicide by jumping from factory roof if working conditions did not improve. Workers remained on roof for 2 days before leaving the roof. 45 of the employees later resigned.',
    date: '01/02/2012',
    year: '2012',
    theme: 'Foreign Working Conditions',
    protest: 'Foreign Working Conditions',
    how: 'Mass-suicide threat',
    actionDoneBy: 'Factory Workers',
    numberOfPeople: '150',
    location: 'Wuhan, China (Foxconn Plant)',
    country: 'China',
    source: [
      'https://www.telegraph.co.uk/news/worldnews/asia/china/9006988/Mass-suicide-protest-at-Apple-manufacturer-Foxconn-factory.html',
    ],
  },
  {
    id: 86,
    companyName: 'Paypal',
    protestColor: '#263692',
    event: "The 'Anonymous' collective promotes legal boycott of PayPal.",
    date: '06/27/2011',
    year: '2011',
    theme: 'Ethics (General)',
    protest: 'Ethics',
    how: 'Boycott',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '',
    location: 'Internet',
    country: 'Internet',
    source: [
      'https://www.forbes.com/sites/parmyolson/2011/07/27/anonymous-calls-for-mass-boycott-of-paypal/',
      'http://www.nbcnews.com/id/43913282/ns/technology_and_science-security/t/anonymous-lulzsec-hacking-groups-call-paypal-boycott/',
    ],
  },
  {
    id: 87,
    companyName: 'Apple',
    protestColor: '#263692',
    event:
      "In response to Apple deeming a 'gay cure' app to have 'no objectionable content' and allowing it to circulate on the App store, gay rights activists responded by creating a petition to have it removed and for the company to respond.",
    date: '03/21/2011',
    year: '2011',
    theme: 'Ethics (LGBTQ)',
    protest: 'Ethics',
    how: 'Petition (Online)',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '80,000',
    location: 'Internet',
    country: 'Internet',
    source: [
      'https://mashable.com/2011/03/21/apple-gay-cure-app/',
    ],
  },
  {
    id: 88,
    companyName: 'Apple',
    protestColor: '#231F20',
    event:
      "Thousands of factory workers were involved in a violent protest after workers' end of the year bonuses were eliminated. Earlier in the year the factory was reported to have suffered 4 deaths after workers were exposed to hazardous chemicals. The factory manufactured parts for companies such as Apple and Nokia.",
    date: '01/15/2010',
    year: '2010',
    theme: 'Foreign Working Conditions',
    protest: 'Foreign Working Conditions',
    how: 'Protest',
    actionDoneBy: 'Factory Workers',
    numberOfPeople: '2,000',
    location: 'Suzhou, China',
    country: 'China',
    source: [
      'https://www.pri.org/stories/2010-01-26/apple-news-workers-rights-focus-again',
    ],
  },
  {
    id: 89,
    companyName: 'Intel',
    protestColor: '#263692',
    event:
      'Hundreds of ultra-Orthodox Jews protested an Intel electronic chip plant in Israel because work was taking place at the plant on the Jewish Sabbath.',
    date: '11/14/2009',
    year: '2009',
    theme: 'Ethics (Religion)',
    protest: 'Ethics',
    how: 'Protest',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '200+',
    location: 'Israel',
    country: 'Israel',
    source: [
      'https://www.reuters.com/article/israel-intel/ultra-orthodox-jews-protest-at-intel-israel-plant-idUSLE14196720091114',
    ],
  },
  {
    id: 90,
    companyName: 'Intel',
    protestColor: '#EE343D',
    event:
      'Roughly 500 workers went on strike in Sichuan, China demanding equal pay for equal work.',
    date: '08/18/2009',
    year: '2009',
    theme: 'Labor / Wage',
    protest: 'Labor/Wage',
    how: 'Walk-out',
    actionDoneBy: 'Workers',
    numberOfPeople: '500',
    location: 'Sichuan, China',
    country: 'China',
    source: [
      'https://clb.org.hk/content/intel-workers-sichuan-strike-over-unequal-pay-equal-work',
    ],
  },
  {
    id: 91,
    companyName: 'HP',
    protestColor: '#EE343D',
    event:
      "Roughly a dozen protestors climbed the roof of HP's Palo Alto headquarters and painted the words 'Hazardous Products' on the roof of the building.",
    date: '07/28/2009',
    year: '2009',
    theme: 'Environmental Cause',
    protest: 'Environmental Cause',
    how: 'Protest',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '12',
    location: 'Palo Alto',
    country: 'USA',
    source: [
      'https://www.mercurynews.com/2009/07/28/greenpeace-protests-hps-use-of-hazardous-chemicals-by-painting-on-companys-roof/',
    ],
  },
  {
    id: 92,
    companyName: 'Cisco',
    protestColor: '#EE343D',
    event:
      "A group of laid-off janitors and allies protesting and disrupting the keynote speech of CEO John Chambers. The event took place following a wave of firings of the company's janitorial staff.",
    date: '06/30/2009',
    year: '2009',
    theme: 'Labor / Wage',
    protest: 'Labor/Wage',
    how: 'Protest',
    actionDoneBy: 'Workers',
    numberOfPeople: '',
    location: 'San Francisco',
    country: 'USA',
    source: [
      'https://www.ibtimes.com/cisco-live-2009-protest-disrupted-cisco-executive-keynotes-287948',
    ],
  },
  {
    id: 93,
    companyName: 'Cisco',
    protestColor: '#EE343D',
    event:
      'Dozens of janitors stage 2 day hunger strike in response to 75 janitors being laid off.',
    date: '06/01/2009',
    year: '2009',
    theme: 'Labor / Wage',
    protest: 'Labor/Wage',
    how: 'Protest (Hunger Strike)',
    actionDoneBy: 'Workers',
    numberOfPeople: '24+',
    location: 'San Francisco',
    country: 'USA',
    source: [
      'https://www.ibtimes.com/cisco-live-2009-protest-disrupted-cisco-executive-keynotes-287948',
    ],
  },
  {
    id: 94,
    companyName: 'Cisco',
    protestColor: '#EE343D',
    event:
      'Approximately one hundred janitors and allies protested outside of the Cisco Systems Inc. headquarters following the loss of 75 janitors were fired.',
    date: '05/01/2009',
    year: '2009',
    theme: 'Labor / Wage',
    protest: 'Labor/Wage',
    how: 'Protest',
    actionDoneBy: 'Workers',
    numberOfPeople: '100',
    location: 'San Jose',
    country: 'USA',
    source: [
      'https://www.bizjournals.com/sanjose/stories/2009/04/27/daily89.html',
    ],
  },
  {
    id: 95,
    companyName: 'Cisco',
    protestColor: '#EE343D',
    event:
      'As part of a protest across the US, hundreds of protestors marched against the firings of janitors at Cisco Systems.',
    date: '04/29/2009',
    year: '2009',
    theme: 'Labor / Wage',
    protest: 'Labor/Wage',
    how: 'Protest',
    actionDoneBy: 'Workers',
    numberOfPeople: '200+',
    location: 'San Jose',
    country: 'USA',
    source: [
      'https://www.huffpost.com/entry/cisco-janitors-take-to-th_b_177212',
    ],
  },
  {
    id: 96,
    companyName: 'Facebook',
    protestColor: '#263692',
    event:
      "A small group of people in Paris protest the redesign of Facebook's website.",
    date: '04/19/2009',
    year: '2009',
    theme: 'Consumer Outrage',
    protest: 'Consumer Outrage',
    how: 'Protest',
    actionDoneBy: 'Concerned Citizens',
    numberOfPeople: '24',
    location: 'Paris, France',
    country: 'France',
    source: [
      'https://techcrunch.com/2009/04/19/french-fury-parisians-hit-the-streets-in-protest-against-facebook-redesign/',
    ],
  },
  {
    id: 97,
    companyName: 'HP',
    protestColor: '#EE343D',
    event:
      'In September 2008, HP announced it was cutting 7.5% of its workforce. Thousands of these jobs were in Europe and number of demonstrations took place across the continent to protest the layoffs and working conditions.',
    date: '01/30/2009',
    year: '2009',
    theme: 'Labor / Wage',
    protest: 'Labor/Wage',
    how: 'Protest',
    actionDoneBy: 'Workers',
    numberOfPeople: '',
    location: 'Worldwide',
    country: 'France',
    source: [
      'https://www.eurofound.europa.eu/publications/article/2009/europe-wide-protests-against-hewlett-packard-job-cuts',
    ],
  },
];
